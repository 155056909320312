import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/layout.js";
import Q1Graph from '../../../../components/specific/CSEC/Physics/2005/Q1Graph-2005.jsx';
import Q1Graph2 from '../../../../components/specific/CSEC/Physics/2005/Q1Graph2-2005.jsx';
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`May/June 2005 Paper 2`}</h1>
    <div className='tier1-3 ques'>
    <span>1.</span>
    <span>In this question you will find the refractive index of a rectangular block of glass. A student was given this experiment as a CXC SBA activity and produced the following results.</span>
    </div>
    <table className='ques'>
    <thead>
        <tr>
            <th>Angle of incidence (i/degrees)</th>
            <th>Angle of refraction (r/degrees)</th>
            <th>Sin &icirc;</th>
            <th>Sin &#531;</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>10.0</td>
            <td>6.0</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>20.0</td>
            <td>12.0</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>30.0</td>
            <td>18.0</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>40.0</td>
            <td>24.0</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>50.0</td>
            <td>30.0</td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>60.0</td>
            <td>66.0</td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    </table>
    <div className='tier2 ques'>
    <span>(a)</span>
    <span>Complete the table above</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <table>
    <thead>
        <tr>
            <th>Angle of incidence (i/degrees)</th>
            <th>Angle of refraction (r/degrees)</th>
            <th>Sin &icirc;</th>
            <th>Sin &#531;</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>10.0</td>
            <td>6.0</td>
            <td>0.17</td>
            <td>0.10</td>
        </tr>
        <tr>
            <td>20.0</td>
            <td>12.0</td>
            <td>0.34</td>
            <td>0.21</td>
        </tr>
        <tr>
            <td>30.0</td>
            <td>18.0</td>
            <td>0.50</td>
            <td>0.31</td>
        </tr>
        <tr>
            <td>40.0</td>
            <td>24.0</td>
            <td>0.64</td>
            <td>0.41</td>
        </tr>
        <tr>
            <td>50.0</td>
            <td>30.0</td>
            <td>0.77</td>
            <td>0.50</td>
        </tr>
        <tr>
            <td>60.0</td>
            <td>36.0</td>
            <td>0.87</td>
            <td>0.59</td>
        </tr>
    </tbody>
    </table>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>Plot a graph of Sin &icirc; against Sin &#531; on a sheet of graph paper.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p className='note'>
Please note that the following graph is drawn for demonstration purposes. 
      <p>{`The graph for the exam's solution should have a title, labels for the axes and other necessary information.`}</p>
    </p>
    <Q1Graph mdxType="Q1Graph" />
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>Find the slope, n, of the graph.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`The slope, n, is given by`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Δ`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mover parentName="mrow" {...{
                        "accent": "true"
                      }}><mi parentName="mover">{`i`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Δ`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mover parentName="mrow" {...{
                        "accent": "true"
                      }}><mi parentName="mover">{`r`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mrow parentName="mfrac"><mn parentName="mrow">{`0.87`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.77`}</mn></mrow><mrow parentName="mfrac"><mn parentName="mrow">{`0.59`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.50`}</mn></mrow></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`0.10`}</mn><mn parentName="mfrac">{`0.09`}</mn></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1.11`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`o`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`3`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`s`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mi parentName="mrow">{`f`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= \\frac{\\Delta sin \\hat{i}}{\\Delta sin \\hat{r}}\\\\ = \\frac{0.87 - 0.77}{0.59 - 0.50}\\\\ = \\frac{0.10}{0.09}\\\\ = 1.11~to~3~s.f`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.286em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.6em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`Δ`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`s`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`in`}</span><span parentName="span" {...{
                              "className": "mord accent"
                            }}><span parentName="span" {...{
                                "className": "vlist-t"
                              }}><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.6944em"
                                    }
                                  }}><span parentName="span" {...{
                                      "style": {
                                        "top": "-3em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "3em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "mord mathnormal",
                                        "style": {
                                          "marginRight": "0.02778em"
                                        }
                                      }}>{`r`}</span></span><span parentName="span" {...{
                                      "style": {
                                        "top": "-3em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "3em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "accent-body",
                                        "style": {
                                          "left": "-0.1944em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "mord"
                                        }}>{`^`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`Δ`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`s`}</span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`in`}</span><span parentName="span" {...{
                              "className": "mord accent"
                            }}><span parentName="span" {...{
                                "className": "vlist-t"
                              }}><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.923em"
                                    }
                                  }}><span parentName="span" {...{
                                      "style": {
                                        "top": "-3em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "3em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "mord mathnormal"
                                      }}>{`i`}</span></span><span parentName="span" {...{
                                      "style": {
                                        "top": "-3.2285em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "3em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "accent-body",
                                        "style": {
                                          "left": "-0.25em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "mord"
                                        }}>{`^`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0908em",
                  "verticalAlign": "-0.7693em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.59`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`−`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.50`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.87`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`−`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.77`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.7693em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.09`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`0.10`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1.11`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span></span></span></span></span></div>
    <div className='tier2 ques'>
    <span>(d)</span>
    <span>What does the slope, n, of the graph represent?</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`The slope of the graph represents the refractive index of the glass.`}</p>
    <div className='tier2 ques'>
    <span>(e)</span>
    <span>Draw a labelled diagram identifying the apparatus used and angles measured by the student to obtain the results.</span>
    </div>
    <p>{`Watch the following `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=CdjjDsb5Fz8"
      }}>{`video`}</a>{` to observe the experiment and make a note of the apparatus used.`}</p>
    <p>{`The video shows the use of:`}</p>
    <ul>
    <li>A blank sheet of paper</li>
    <li>A transparent glass block</li>
    <li>A ruler or straight edge</li>
    <li>A protractor</li>
    <li>A pencil</li>
    <li>Pins</li>
    </ul>
    <p>{`Include this information in your `}<em parentName="p">{`labelled`}</em>{` diagram.`}</p>
    <div className='tier2 ques'>
    <span>(f)</span>
    <span>A ray of light is incident at an angle of 35<sup>o</sup>. With the aid of dotted lines use your graph to calculate the angle of refraction produced.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`The angle of incidence is given as `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`3`}</mn><msup parentName="mrow"><mn parentName="msup">{`5`}</mn><mi parentName="msup">{`o`}</mi></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`35^{o}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6644em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`5`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6644em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span></span></span></span></span>{`, but the plotted graph is showing `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mtext parentName="mrow">{` `}</mtext><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`i`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Sin~\\hat{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.923em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`in`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.923em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal"
                        }}>{`i`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.2285em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.25em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span></span></span></span></span></span></span></span>{` vs. `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mtext parentName="mrow">{` `}</mtext><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`r`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Sin~\\hat{r}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`in`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.02778em"
                          }
                        }}>{`r`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.1944em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span></span></span></span></span></span></span></span>{`. `}</p>
    <p>{`Therefore, the first thing we must do is calculate the value of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`3`}</mn><msup parentName="mrow"><mn parentName="msup">{`5`}</mn><mi parentName="msup">{`o`}</mi></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Sin(35^{o})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`in`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`5`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6644em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`.`}</p>
    <p>{`Sin(35)
= 0.57 to 2 s.f.`}</p>
    <Q1Graph2 mdxType="Q1Graph2" />
    <p>{`If you draw a horizontal line originating from 0.57 on the y-axis, it should cut the plotted line in the region where `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`n`}</mi><mtext parentName="mrow">{` `}</mtext><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`r`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`Sin~\\hat{r}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`in`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.02778em"
                          }
                        }}>{`r`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.1944em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span></span></span></span></span></span></span></span>{` is 0.85.`}</p>
    <p>{`Therefore, the angle of refraction, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`r`}</mi><mo parentName="mover">{`^`}</mo></mover></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\hat{r}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.02778em"
                          }
                        }}>{`r`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.1944em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span></span></span></span></span></span></span></span>{` , is,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`i`}</mi><msup parentName="mrow"><mi parentName="msup">{`n`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`0.85`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`58.21`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`r`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`58`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`d`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`s`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`o`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`2`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`s`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mi parentName="mrow">{`f`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= Sin^{-1}(0.85)\\\\ = 58.21~or~58~degrees~to~2~s.f`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`i`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0.85`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`58.21`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`or`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`58`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`d`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`rees`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span></span></span></span></span></div>
    <div className='tier1-1 ques'>
    <span>2</span>
    <span>(a)</span>
    <span>(i)</span>
    <span>State the principle of conservation of energy.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The principle of conservation of energy states that energy can neither be created nor destroyed. It may transform from one type to another.`}</p>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Define the term 'potential energy'.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`Potential energy is energy stored by a body due to its position, state, or composition.`}</p>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>Give ONE example of a body possessing potential energy.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`An elevated object possesses gravitational potential energy.`}</p>
    <div className='tier3 ques'>
    <span>(iv)</span>
    <span>Define the term 'kinetic energy'.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`Kinetic energy is the energy possessed by an object in motion.`}</p>
    <div className='tier3 ques'>
    <span>(v)</span>
    <span>Give ONE example of a body possessing kinetic energy.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`An athlete that is sprinting on the track possesses kinetic energy.`}</p>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>
        At a football match between two college teams, the referee ordered a free kick. The ball of mass 1.5kg was placed at rest. The kick was about to be taken by an eager footballer.
        What was the potential energy of the ball just before the kick was taken?</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The ball had no gravitational potential energy because it was not elevated, and neither its state nor composition provided the ball with potential energy.`}</p>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>The footballer kicked the ball and it was caught by the opposing goalkeeper, 4 metres above the ground. The ball was travelling at 10 ms-1.</span>
    </div>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Calculate the potential energy of the ball just before it was caught.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`Potential energy `}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`m`}</mi><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`h`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1.5`}</mn><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`9.81`}</mn><mi parentName="mrow">{`m`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`/`}</mi><msup parentName="mrow"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`4`}</mn><mi parentName="mrow">{`m`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`58.86`}</mn><mi parentName="mrow">{`J`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`r`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`58.9`}</mn><mi parentName="mrow">{`J`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`o`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`3`}</mn><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`s`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi><mi parentName="mrow">{`f`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`.`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= mgh\\\\ = 1.5 kg~x~9.81 {m}/{s^{2}}~x~4 m\\\\ = 58.86 J~or~58.9J~to~3~s.f.`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`h`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1141em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1.5`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`9.81`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`m`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`/`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`s`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.8641em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.113em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`4`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`58.86`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`or`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`58.9`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span></span></span></span></span></div>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Calculate the kinetic energy of the ball just before it was caught.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`Kinetic energy`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac><mi parentName="mrow">{`m`}</mi><msup parentName="mrow"><mi parentName="msup">{`v`}</mi><mn parentName="msup">{`2`}</mn></msup><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`1.5`}</mn><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mn parentName="mrow">{`1`}</mn><msup parentName="mrow"><mn parentName="msup">{`0`}</mn><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mfrac parentName="mrow"><mi parentName="mfrac">{`m`}</mi><mi parentName="mfrac">{`s`}</mi></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`75`}</mn><mi parentName="mrow">{`J`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= \\frac{1}{2} mv^{2}\\\\ = \\frac{1}{2}~x~1.5kg~x~(10^{2})\\frac{m}{s}\\\\ = 75 J`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`2`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`v`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0074em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`2`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1.5`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1076em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`s`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`m`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`75`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span></span></span></span></span></div>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>After the ball was caught, what was the kinetic energy converted into?</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The kinetic energy was converted into gravitational potential, sound and heat energy.`}</p>
    <div className='tier1-2 ques'>
    <span>3</span>
    <span>(a)</span>
    <span>Define the 'heat capacity of a substance' and state its SI unit.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The 'heat capacity of a substance', C, is the heat energy required to raise the temperature of the substance by 1K. `}</p>
    <p>{`The unit for heat capacity is J/K. However, 'J' is not an SI unit so we'll have to derive the SI unit for 'J'.`}</p>
    <p>{`'J' is the unit for energy, so let's derive the SI unit for 'J' from the formula for either Kinetic energy or Potential energy:`}</p>
    <p>{`The formula for kinetic energy is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac><mi parentName="mrow">{`m`}</mi><msup parentName="mrow"><mi parentName="msup">{`v`}</mi><mn parentName="msup">{`2`}</mn></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{1}{2}mv^{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1901em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8451em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`v`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mn parentName="mfrac">{`1`}</mn><mn parentName="mfrac">{`2`}</mn></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{1}{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1901em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8451em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{` is a constant, so it does not have a unit. The SI unit for mass(m) is 'kg', and the SI unit for velocity(v) is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mi parentName="mfrac">{`m`}</mi><mi parentName="mfrac">{`s`}</mi></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{m}{s}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.0404em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6954em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`s`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`m`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{`.`}</p>
    <p>{`Let's start with the formula for kinetic energy:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mfrac parentName="mrow"><mi parentName="mfrac">{`m`}</mi><mi parentName="mfrac">{`s`}</mi></mfrac><msup parentName="mrow"><mo parentName="msup" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mn parentName="msup">{`2`}</mn></msup><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mfrac parentName="mrow"><msup parentName="mfrac"><mi parentName="msup">{`m`}</mi><mn parentName="msup">{`2`}</mn></msup><msup parentName="mfrac"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`kg~x~(\\frac{m}{s})^{2}\\\\ kg~x~\\frac{m^{2}}{s^{2}}\\\\`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.7936em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1076em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`s`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`m`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mclose"
              }}><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.1771em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.4911em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`s`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.7401em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.989em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`m`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.8141em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-3.063em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span></span></span></span></div>
    <p>{`Whereas, the formula for potential energy is mgh. The SI unit for the acceleration due to gravity(g) is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mi parentName="mfrac">{`m`}</mi><msup parentName="mfrac"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{m}{s^{2}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.0404em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6954em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`s`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.7463em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.786em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.394em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`m`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{` and the SI unit for height(h) is 'm'.`}</p>
    <p>{`Let's try this again with the formula for potential energy:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mfrac parentName="mrow"><mi parentName="mfrac">{`m`}</mi><msup parentName="mfrac"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup></mfrac><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`m`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mfrac parentName="mrow"><msup parentName="mfrac"><mi parentName="msup">{`m`}</mi><mn parentName="msup">{`2`}</mn></msup><msup parentName="mfrac"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup></mfrac><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`kg~x~\\frac{m}{s^{2}}~x~m\\\\ kg~x~\\frac{m^{2}}{s^{2}}\\\\`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.7936em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1076em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`s`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.7401em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.989em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`m`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.1771em",
                  "verticalAlign": "-0.686em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.4911em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`s`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.7401em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.989em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`m`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.8141em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-3.063em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.686em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span></span></span></span></div>
    <p>{`Therefore the SI Unit for J is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><msup parentName="mrow"><mi parentName="msup">{`m`}</mi><mn parentName="msup">{`2`}</mn></msup></mrow><msup parentName="mfrac"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{kgm^{2}}{s^{2}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.415em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.07em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`s`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.7463em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.786em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.4461em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03148em"
                                }
                              }}>{`k`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`m`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.8913em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.931em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{` and so the SI unit for heat capacity is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mfrac parentName="mrow"><mrow parentName="mfrac"><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><msup parentName="mrow"><mi parentName="msup">{`m`}</mi><mn parentName="msup">{`2`}</mn></msup></mrow><mrow parentName="mfrac"><msup parentName="mrow"><mi parentName="msup">{`s`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`∙`}</mo><mi parentName="mrow">{`K`}</mi></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\frac{kgm^{2}}{s^{2}\\bullet K}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.415em",
                  "verticalAlign": "-0.345em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.07em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.655em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`s`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.7463em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.786em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mbin mtight"
                              }}>{`∙`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.07153em"
                                }
                              }}>{`K`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.4461em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03148em"
                                }
                              }}>{`k`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal mtight"
                                }}>{`m`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.8913em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.931em",
                                            "marginRight": "0.0714em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.5em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size3 size1 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.345em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span>{` .`}</p>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>Name the THREE modes of heat transfer.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The three modes of heat transfer are conduction, convection and radiation.`}</p>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>A well-insulated hot water tank is used to supply hot water to a residential dwelling house. The immersion heating element inside the tank has a power rating of 2200 W and the tank contains 125 kg of water at 28 <sup>o</sup>C. Calculate:</span>
    </div>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>the heat energy supplied by the heating element in 2 hours.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`Remember that power is the rate of transfer of energy. The question states that the power rating of the heating element is 2200 W.`}</p>
    <p>{`1 Watt(W) is equivalent to 1 Joule(J) of energy transferred per second (s). (i.e. W = J/s).`}</p>
    <p>{`This means that the heating element transfers heat energy of 2200 Joules per second (2200 J/s).`}</p>
    <p>{`The heating element is converting electrical energy to heat energy.`}</p>
    <p>{`If the heating element transfers 2200 J in one second, and there are 3600 seconds per hour then in 2 hours the heating element transfers 2200 J/s x 3600 s = 7,920,000 J or 7.92 MJ of energy`}</p>
    <p className='note'>60 seconds = 1 minute, and 60 minutes = 1 hour. <br /> Therefore, 1 hour = 60 minutes x 60 seconds/minute = 3600 seconds</p>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>the heat energy supplied to the water, given that its temperature increases to 58<sup>o</sup>C. </span>
    </div>
    <p className='ques'>[specific heat capacity of water is 4200 J kg<sup>-1</sup> K<sup>-1</sup>]</p>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The heat energy supplied to the water to raise its temperature to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`5`}</mn><msup parentName="mrow"><mn parentName="msup">{`8`}</mn><mi parentName="msup">{`o`}</mi></msup><mi parentName="mrow">{`C`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`58^{o}C`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`5`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`8`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6644em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span></span></span></span></span>{` is given by using the following formula:
E = `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`m`}</mi><mi parentName="mrow">{`c`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`Δ`}</mi><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`mc\\Delta T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`c`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`Δ`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{`. `}</p>
    <p>{`The first thing that needs to be done is to ensure that the units of the quantities being used are consistent.`}</p>
    <p>{`The temperature in the question is given in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msup parentName="mrow"><mrow parentName="msup"></mrow><mi parentName="msup">{`o`}</mi></msup><mi parentName="mrow">{`C`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`^{o}C`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span"></span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.6644em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`o`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`C`}</span></span></span></span></span>{`, but the temperature component in the specific heat capacity is given in K.
So, let's convert the temperatures given in the question to K.`}</p>
    <p className='note'>To convert from <sup>o</sup>C to degrees K, we must add 273.</p>
    <p>{`So, the initial temperature: `}</p>
    <p>{`28`}<sup>{`o`}</sup>{`C = 28 + 273 = 301 K`}</p>
    <p>{`And the final temperature: `}</p>
    <p>{`58`}<sup>{`o`}</sup>{`C = 58 + 273 = 331 K.`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`Δ`}</mi><mi parentName="mrow">{`T`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\Delta T`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`Δ`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`T`}</span></span></span></span></span>{` represents the change in the value of the temperature due to the energy supplied by the heating element.
In this case, the temperature was raised by 331 - 301 = 30 K. `}</p>
    <p className='note'>If you had subtracted 28<sup>o</sup>C from 58<sup>o</sup>C, the value of the change in temperature would still be 30 but it's good practice to ensure that your units are consistent when performing calculations.</p>
    <p>{`The heat energy supplied is,`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`125`}</mn><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`4200`}</mn><mfrac parentName="mrow"><mi parentName="mfrac">{`J`}</mi><mrow parentName="mfrac"><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`g`}</mi><mo parentName="mrow">{`∙`}</mo><mi parentName="mrow">{`K`}</mi></mrow></mfrac><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`x`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`30`}</mn><mi parentName="mrow">{`K`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`15`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`750`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`000`}</mn><mi parentName="mrow">{`J`}</mi><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`r`}</mi><mtext parentName="mrow">{` `}</mtext><mn parentName="mrow">{`15.75`}</mn><mi parentName="mrow">{`M`}</mi><mi parentName="mrow">{`J`}</mi><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`= 125 kg~x~4200 \\frac{J}{kg\\bullet K}~x~30 K\\\\ = 15,750,000 J~or~15.75 MJ\\\\`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.2408em",
                  "verticalAlign": "-0.8804em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`125`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`4200`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.3603em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03148em"
                              }
                            }}>{`k`}</span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.03588em"
                              }
                            }}>{`g`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mbin"
                            }}>{`∙`}</span><span parentName="span" {...{
                              "className": "mspace",
                              "style": {
                                "marginRight": "0.2222em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.07153em"
                              }
                            }}>{`K`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.09618em"
                              }
                            }}>{`J`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8804em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`30`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.07153em"
                }
              }}>{`K`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8778em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`15`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`750`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`000`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`or`}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`15.75`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10903em"
                }
              }}>{`M`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.09618em"
                }
              }}>{`J`}</span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span></span></span></span></div>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>The heat capacity of the tank, assuming that the tank and the water reach the same final temperature of 58<sup>o</sup>C </span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p className='note'>This question is under review</p>
    <div className='tier1-2 ques'>
    <span>4</span>
    <span>(a)</span>
    <span>Explain what is meant by the term 'magnetic field'.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`A magnetic field is a region in which a magnetic force acts.`}</p>
    <div className='tier2 ques'>
    <span>(a)</span>
    <span>TWO bar magnets are close to each other so that their magnetic fields interact. Sketch the field pattern for the TWO arrangements shown below.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/4+b+i.PNG",
        "alt": "img 4bi"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/4+b+ii.PNG",
        "alt": "img 4bi"
      }}></img></p>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/magnet_repel.png",
        "alt": "repel"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/magnet_attract.png",
        "alt": "attract"
      }}></img></p>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>In Figure 1 below the primary of the transformer is connected to a battery and a switch while the secondary is connected to a centre-zero galvanometer.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/4+c.PNG",
        "alt": "4ci"
      }}></img></p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>Describe what would be observed on the galvanometer when the switch is closed.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`Once the switch is closed, the galvanometer's pointer will deflect since current is now flowing in the circuit.`}</p>
    <div className='tier3 ques'>
    <span>(ii)</span>
    <span>Explain your answer.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`The galvanometer is an electromechanical device used for detecting and measuring electric current. `}</p>
    <p>{`Once current starts flowing through the rectangular coil of the galvanometer, there is an interaction between the radial magnetic field produced by the permanent magnet and the electromagnetic field around the coil.`}</p>
    <p>{`This interaction generates a force that causes the coil and the pointer to rotate once the switch is closed. `}</p>
    <div className='tier3 ques'>
    <span>(iii)</span>
    <span>Describe what would be observed on the galvanometer if the switch is subsequently opened.</span>
    </div>
    <p>{`If the switch is subsequently opened, the pointer will deflect slightly in the opposite direction before coming to rest in the centre (position zero).`}</p>
    <div className='tier3 ques'>
    <span>(iv)</span>
    <span>Deduce what would be observed on the galvanometer if the battery were replaced by a <b>low frequency</b> a.c. supply</span>
    </div>
    <p>{`The pointer's position reflects both the magnitude and the direction of the current flowing through the galvanometer. `}</p>
    <p>{`Since both quantities are constantly changing when an alternating current (a.c.) supply is connected to the galvanometer, we can expect the pointer to fluctuate about the centre (position zero) with a frequency that matches the frequency of the a.c. supply.`}</p>
    <div className='tier3 ques'>
    <span>(v)</span>
    <span>How would these observations be affected if the number of turns on the transformer secondary were significantly increased?</span>
    </div>
    <p>{`If the number of turns on the transformer secondary was significantly increased, then the output voltage of the transformer would also increase significantly.`}</p>
    <p>{`As the power output from the transformer is constant, the output current will be significantly reduced.`}</p>
    <p>{`We can expect the galvanometer's pointer not to deflect as much as it did due to these changes in the transformer.`}</p>
    <div className='tier1-2 ques'>
    <span>5</span>
    <span>(a)</span>
    <span>Identify the logic gates described in the first column of the table below and hence complete the table by writing in the names and logic symbols in the appropriate columns.</span>
    </div>
    <table className='ques'>
    <thead>
    <tr>
    <th>DESCRIPTION</th>
    <th>NAME</th>
    <th>SYMBOL</th>
    </tr>
    </thead>
    <tbody>
        <tr>
            <td>Output is always <b>low</b> except when <b>both</b> inputs are <b>high.</b></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Output is always <b>high</b> except when <b>both</b> inputs are <b>low.</b></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>Output is always <b>high</b> except when <b>both</b> inputs are <b>high.</b></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    
    </table>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <table>
    <thead>
    <tr>
    <th>DESCRIPTION</th>
    <th>NAME</th>
    <th>SYMBOL</th>
    </tr>
    </thead>
    <tbody>
        <tr>
            <td>Output is always <b>low</b> except when <b>both</b> inputs are <b>high.</b></td>
            <td>AND gate</td>
            <td><img src='https://cxcanswers.s3.us-east-2.amazonaws.com/AND-gate.svg' /></td>
        </tr>
        <tr>
            <td>Output is always <b>high</b> except when <b>both</b> inputs are <b>low.</b></td>
            <td>OR gate</td>
            <td><img src='https://cxcanswers.s3.us-east-2.amazonaws.com/OR-gate.svg' /></td>
        </tr>
        <tr>
            <td>Output is always <b>high</b> except when <b>both</b> inputs are <b>high.</b></td>
            <td>NAND gate</td>
            <td><img src='https://cxcanswers.s3.us-east-2.amazonaws.com/NAND-gate.svg' /></td>
        </tr>
    </tbody>
    
    </table>
    <div className='tier2 ques'>
    <span>(b)</span>
    <span>Figure 2 shows a logic circuit</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/5+b+fig2.PNG",
        "alt": "5bfig2"
      }}></img></p>
    <p className='ques'>Examine the logic circuit shown in Figure 2 and complete the TRUTH TABLE below:</p>
    <table className='ques'>
    <thead>
        <tr>
            <th>A</th>
            <th>B</th>
            <th>W</th>
            <th>X</th>
            <th>Y</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>0</td>
            <td>0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>0</td>
            <td>1</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>1</td>
            <td>0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td>1</td>
            <td>0</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    </table>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <table>
    <thead>
        <tr>
            <th>A</th>
            <th>B</th>
            <th>W</th>
            <th>X</th>
            <th>Y</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>0</td>
            <td>0</td>
            <td>1</td>
            <td>0</td>
            <td>0</td>
        </tr>
        <tr>
            <td>0</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>1</td>
        </tr>
        <tr>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
        </tr>
        <tr>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
        </tr>
    </tbody>
    </table>
    <p>{`The logic circuit might be overwhelming to look at all at once, so let's break it down into smaller pieces.`}</p>
    <p>{`Let's isolate each logic gate to make the analysis simpler.`}</p>
    <p>{`W is the output of a NOT gate that has A as its input. So, W is the inverse of the logic state of A. For example, if A is logic state 1, then W is logic state 0 and vice versa.`}</p>
    <p>{`One column down. Two to go.`}</p>
    <p>{`X is the output of an AND gate that has A and B as its inputs. So, X is only logic state 1 when both A and B are logic state 1.`}</p>
    <p>{`Y is also the output of an AND gate, but it has W and B as its inputs. So, Y is only logic state 1 when both W and B are logic state 1.`}</p>
    <div className='tier2 ques'>
    <span>(c)</span>
    <span>The logic circuit of Figure 2 is incorporated into the electrical system of a car as shown in the Figure below, and switches S1 and S2 are operated according to the table below.</span>
    </div>
    <p><img parentName="p" {...{
        "src": "https://cxcanswers.s3.us-east-2.amazonaws.com/5+c+fig3.PNG",
        "alt": "5cfig2"
      }}></img></p>
    <table className='ques'>
    <thead>
        <tr>
            <th></th>
            <th>Engine On</th>
            <th>Engine Off</th>
            <th>Door Open</th>
            <th>Door Closed</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Switch S1</td>
            <td>On</td>
            <td>Off</td>
            <td>Any state</td>
            <td>Any state</td>
        </tr>
        <tr>
            <td>Switch S2</td>
            <td>Any state</td>
            <td>Any state</td>
            <td>On</td>
            <td>Off</td>
        </tr>
    </tbody>
    </table>
    <p>{`Explain what happens if:`}</p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>the DOOR is OPEN and the ENGINE is OFF.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong>{` `}</p>
    <p>{`If the door is open, switch S2 (B) will be turned on (logic state 1), and if the engine is off, switch S1 (A) will be turned off (logic state 0).`}</p>
    <p>{`We can reference the truth table solution for Part (b) above to see the logic state values of X and Y when A is at logic state 0 and B is at logic state 1.`}</p>
    <p>{`When A is at 0 and B is at 1, X is at 0, and Y is at 1. Therefore, Alarm 1 will not make a sound, whereas Alarm 2 will make a sound.`}</p>
    <div className='tier3 ques'>
    <span>(i)</span>
    <span>the DOOR is OPEN and the ENGINE is ON.</span>
    </div>
    <p><strong parentName="p">{`Solution:`}</strong></p>
    <p>{`This case is not in the truth table solution from Part(b), so we'll have to derive the answer ourselves.`}</p>
    <p>{`If the door is open, switch S2 (B) will be turned on (logic state 1). If the engine is on, switch S1 (A) will also be turned on (logic state 1).`}</p>
    <p>{`Alarm 1 (X) is the output of an AND gate. Since both inputs (A & B) are high (logic state 1), then the output X will be high (logic 1) and as a result Alarm 1 will make a sound.`}</p>
    <p>{`W is the output of the NOT gate which has A as its input. Since A is high (logic state 1), then W is low (logic state 0).`}</p>
    <p>{`Alarm 2 (Y) is also the output of an AND gate. However, since one of its inputs is W, and W is low (logic state 0), then the output Y will also be low (logic state 0) and Alarm 2 will not make a sound.`}</p>
    <h1>{`END OF TEST`}</h1>
    <Link to='/' className='button' mdxType="Link">Back to Home page</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      